// 选择企业组件
<template>
  <div class="select-enterprise">
    <!-- drawer -->
    <el-drawer
      size="100%"
      :with-header="false"
      v-model="drawer"
      custom-class="select-drawers"
      :direction="'btt dbody'"
      :before-close="handleClose"
    >
      <TestTracking
        v-if="drawer"
        :mediaConstraints="mediaConstraints"
        @onBack="onBack"
        @success="success"
        :key="showNumber"
      />
    </el-drawer>
  </div>
</template>

<script>
import TestTracking from "./TestTracking";
export default {
  props: ["drawer", "mediaConstraints"],
  data() {
    return {
      showNumber: 0,
    };
  },
  methods: {
    success() {
      this.$emit("success");
    },
    onBack() {
      this.showNumber++;
      this.$emit("onBack");
    },
    handleClose(done) {
      this.showNumber++;
      done();
    },
  },
  mounted() {},
  components: {
    TestTracking,
  },
};
</script>

<style>
.select-drawer {
  width: 100%;
  margin: 0 auto;
}
.select-drawer .el-drawer__body {
  height: 0;
}
.select-drawers {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-image: url(../assets/imgs/home-bg.png);
}
.dbody .el-drawer__body {
  height: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
